const OSS = require('ali-oss');
export async function uploadToOSS(file) {
  console.log('🚀 ~ uploadToOSS ~ file:', file);
  // const data = await getPolicy();
  const { region, accessKeyId, accessKeySecret, securityToken, bucket } = {
    region: 'oss-cn-beijing',
    accessKeyId: 'LTAI5tDBbrciS1GZorh6hYD9',
    accessKeySecret: 'RedBm3zJFAR4f7ISgGqNvO2VyHeOMz',
    bucket: 'yukaid',
  }; // data;
  const client = new OSS({
    region,
    accessKeyId,
    accessKeySecret,
    //   stsToken: securityToken,
    timeout: 60000 * 60,
    bucket,
  });
  try {
    const filname = file.name.replace(/ /g, '_');
    let fileNames = (filname || '').split('.');
    const type = fileNames.length > 1 ? fileNames[fileNames.length - 1] : file.type;
    const filePath = `/oss-direct-transmission/file-static/${Number(new Date())}-${filname}`;
    const result = await client.put(filePath, file);

    let url = result.url.replace(
      'https://yukaid.oss-cn-beijing.aliyuncs.com',
      'https://file.ynzyq.net',
    );
    const fileSizeInBytes = file.size;
    const fileSizeInKB = fileSizeInBytes / 1024;

    return {
      code: 0,
      data: {
        format: type,
        name: file.name,
        size: fileSizeInKB,
        url: url,
      },
    };
  } catch (err) {
    return {
      code: -1,
      message: err,
    };
  }
}
