import Vue from 'vue';
import { Dialog } from 'element-ui';
function destoryModal(instance) {
  if (instance.$el && instance.$el.parentNode) {
    instance.$el.parentNode.removeChild(instance.$el);
  }
  instance.$destroy();
}

function createModal(options = {}) {
  const guid = Math.random().toString(36).substring(2);
  const ModalConstructor = Vue.extend({
    components: {
      'el-dialog': Dialog,
      ...(options?.component?.is ? { 'custom-component': options?.component?.is } : {}),
    },
    data: () => ({
      loading: false,
    }),
    render(h) {
      const slots = options.slots || {};
      return h(
        'el-dialog',
        {
          props: {
            visible: true,
            title: options.title,
            width: options.width,
            'append-to-body': true,
            'destroy-on-close': true,
            ...(options?.dialogProps ?? {}),
          },
          on: {
            'update:visible': (val) => {
              if (!val) {
                destoryModal(this);
              }
            },
          },
        },
        [
          options?.component?.is
            ? h('custom-component', {
                props: options?.component?.props,
                key: guid,
                ref: guid,
                on: {
                  'update:loading': (val) => {
                    this.loading = val;
                  },
                },
              })
            : null,
          h('span', { slot: 'footer' }, [
            h(
              'el-button',
              {
                props: {
                  size: 'small',
                },
                on: {
                  click: () => {
                    destoryModal(this);
                  },
                },
              },
              '取消',
            ),
            h(
              'el-button',
              {
                props: {
                  size: 'small',
                  type: 'primary',
                  loading: this.loading,
                },
                on: {
                  click: () => {
                    this.$nextTick(() => {
                      const componentInstance = this.$refs[guid] ?? null;
                      if (typeof options.onConfirm === 'function') {
                        options.onConfirm({ componentInstance });
                      }
                    });
                  },
                },
              },
              '确定',
            ),
          ]),
        ],
      );
    },
  });

  const instance = new ModalConstructor().$mount();
  document.body.appendChild(instance.$el);
  instance.destoryModal = () => {
    destoryModal(instance);
  };
  return instance;
}

export default {
  install(Vue) {
    Vue.prototype.$createCustomModal = createModal;
  },
};
