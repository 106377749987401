const state = {
  selectRow: localStorage.getItem('set_policy_detail') ? JSON.parse(localStorage.getItem('set_policy_detail')) :  null
};
const mutations = {
  set_policy_detail(state, data) {
    state.selectRow = data;
    localStorage.setItem('set_policy_detail', JSON.stringify(data));
  },
};
export default {
  state,
  mutations,
};
