import _ from 'lodash';
export const describeColorList = ['yn', 'green', 'orange', 'blue', 'purple', 'red', 'default'];
export const describeColorType = {
  yn: { bgColor: '#FCF3EC', borderColor: '#FF700A' },
  green: { bgColor: '#E9F8F4', borderColor: '#12E6AD' },
  orange: { bgColor: '#FFF5EA', borderColor: '#FF9015' },
  blue: { bgColor: '#E3EEFA', borderColor: '#1485FF' },
  purple: { bgColor: '#F1F0FD', borderColor: '#2614FF' },
  red: { bgColor: '#FCEEED', borderColor: '#FF2414' },
  default: { bgColor: '#FFF5EA', borderColor: '#FF9015' },
};

export const stageCategoryType = {
  招商签约: { color: '#84A4FF' },
  开发选址: { color: '#E278FF' },
  设计出图: { color: '#FFB385' },
  工程施工: { color: '#64CDFF' },
  筹备开业: { color: '#44D7B6' },
};
export const radioGroupList = [
  {
    label: '按分公司',
    value: 'area',
    disable: false,
  },
  {
    label: '按省份',
    value: 'province',
    disable: true,
  },
  {
    label: '按城市',
    value: 'city',
    disable: true,
  },
];
// NOT_START(0,"未开始"),ING(10,"进行中"),FINISHED(20,"已完成"),ABNORMAL(30,"异常"),NOT_EXECUTE(40,"不执行"),STOP(50,"暂停");
export const NOT_START = 0;
export const ING = 10;
export const FINISHED = 20;
export const ABNORMAL = 30;
export const NOT_EXECUTE = 40;
export const STOP = 50;
export const statusType = {
  0: { name: '未开始', tagColor: 'gray' },
  10: { name: '进行中', tagColor: 'blue' },
  20: { name: '已完成', tagColor: 'green' },
  30: { name: '异常', tagColor: 'red' },
  40: { name: '不执行', tagColor: 'gray' },
  50: { name: '暂停', tagColor: 'gray' },
};

export const statusTypeList = [
  { name: '未开始', code: NOT_START },
  { name: '进行中', code: ING },
  { name: '已完成', code: FINISHED },
  { name: '不执行', code: NOT_EXECUTE },
  { name: '暂停', code: STOP },
];
export const tabs1 = [
  {
    key: 'opening',
    name: '在营',
  },
  {
    key: 'developing',
    name: '开发中',
  },
  // {
  //   key: 'constructing',
  //   name: '营建中',
  // },
  // {
  //   key: 'to_open',
  //   name: '竣工待开业',
  // },
];

export const tabs2 = [
  {
    key: 'cur_year_open',
    name: '本年开店',
  },
  // {
  //   key: 'cur_year_sign',
  //   name: '本年签约',
  // },
  // {
  //   key: 'cur_year_close',
  //   name: '本年闭店',
  // },
];

// 颜色由深到浅
export const mapColorSeries = [
  '#B34B00',
  '#FF7A19',
  '#FF9040',
  '#FFAE73',
  '#FFCBA6',
  '#FFE1CC',
  '#FFF0E6',
];
export const activeMapColor = '#FFF0CC';
// 颜色由深到浅
export const mapColorMapArea = [
  {
    areaName: '华东',
    color: '#FFBE99',
    showValue: 1,
  },
  {
    areaName: '华西',
    color: '#FFF7F2',
    showValue: 2,
  },
  {
    areaName: '华南',
    color: '#FF9C66',
    showValue: 3,
  },
  {
    areaName: '华北',
    color: '#FFDFCC',
    showValue: 4,
  },
  {
    areaName: '华中',
    color: '#FFCFB3',
    showValue: 5,
  },
];

//人员选择维度
export const selectUserAttr = {
  USER: 1,
  POSITION: 2,
  ROLE: 3,
};
export const staffAttr = 'STAFF';
export const deptAttr = 'DEPT';
export const roleAttr = 'ROLE';

export const selectUserOptions = [
  { label: selectUserAttr.ROLE, name: '按角色选', desc: '角色' },
  { label: selectUserAttr.POSITION, name: '按职位选', desc: '职位' },
  { label: selectUserAttr.USER, name: '按人员选', desc: '人员' },
];

import moment from 'moment';
export const curMonth = [
  moment().startOf('month').format('YYYY-MM-DD'),
  moment().endOf('month').format('YYYY-MM-DD'),
];
export const curWeek = [moment().startOf('week'), moment().endOf('week')];
// antDesign  a-range-picker
export const ranges = {
  今天: [moment(), moment()],
  本周: curWeek,
  本月: [moment().startOf('month'), moment().endOf('month')],
  本季度: [moment().startOf('quarter'), moment().endOf('quarter')],
  昨天: [moment(moment().subtract(1, 'day')), moment(moment().subtract(1, 'day'))],
  上周: [
    moment()
      .week(moment().week() - 1)
      .startOf('week'),
    moment()
      .week(moment().week() - 1)
      .endOf('week'),
  ],
  上月: [
    moment().subtract(1, 'months').startOf('month'),
    moment().subtract(1, 'months').endOf('month'),
  ],
  上季度: [
    moment()
      .quarter(moment().quarter() - 1)
      .startOf('quarter'),
    moment()
      .quarter(moment().quarter() - 1)
      .endOf('quarter'),
  ],
  近7天: [moment().subtract(6, 'days'), moment()],
  近14天: [moment().subtract(13, 'days'), moment()],
  近30天: [moment(new Date().getTime() - 3600 * 1000 * 24 * 30), moment()],
  近90天: [moment(new Date().getTime() - 3600 * 1000 * 24 * 90), moment()],
};

//下载回调信息
export const downloadCallback = (res, fileName) => {
  const content = res.data;
  const blob = new Blob([res.data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  if ('download' in document.createElement('a')) {
    // 非IE下载
    const elink = document.createElement('a');
    elink.download = fileName;
    elink.style.display = 'none';
    elink.href = URL.createObjectURL(blob);
    document.body.appendChild(elink);
    elink.click();
    URL.revokeObjectURL(elink.href); // 释放URL 对象
    document.body.removeChild(elink);
  } else {
    // IE10+下载
    navigator.msSaveBlob(blob, fileName);
  }
};
export const getPopupContainer = (triggerNode) => {
  return triggerNode.parentNode || document.body;
};

export const statusSort = (data) => {
  return data.sort((a, b) => {
    const timeA = a.handlers.length > 0 ? new Date(a.handlers[0].handleTime).getTime() : 0;
    const timeB = b.handlers.length > 0 ? new Date(b.handlers[0].handleTime).getTime() : 0;
    return timeA - timeB;
  });
};
export const statusReverse = (list = []) => {
  function isIng(itemR) {
    let flag = (itemR.handlers || []).some((itemH) => {
      return itemH.handleType == 0;
    });
    return flag;
  }

  let result = _.cloneDeep(list).reverse();
  let statusList10 = result.filter((item) => isIng(item));
  let statusList = result.filter((item) => !isIng(item));
  return [...statusList10, ...statusList];
};
