import moment from 'moment';
import _ from 'lodash';
import { validatePhone } from '@/util/validate';

export const getWeekData = (a, b, c) => {
  let date = new Date(a, parseInt(b) - 1, c),
    w = date.getDay(),
    d = date.getDate();
  if (w == 0) {
    w = 7;
  }
  let config = {
    getMonth: date.getMonth() + 1,
    getYear: date.getFullYear(),
    getWeek: Math.ceil((d + 6 - w) / 7),
  };
  return config;
};
export const handleFormateData = (obj, result) => {
  if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      if (obj[i].hasOwnProperty('children') && obj[i].children.length > 0) {
        handleFormateData(obj[i].children, result);
      } else {
        result.push(obj[i]);
      }
    }
  }
};
export const getYearWeek = function (a, b, c) {
  /*
     date1是当前日期
     date2是当年第一天
    d是当前日期是今年第多少天
    用d + 当前年的第一天的周差距的和在除以7就是本年第几周
    */
  let date1 = new Date(a, parseInt(b) - 1, c),
    date2 = new Date(a, 0, 1),
    d = Math.round((date1.valueOf() - date2.valueOf()) / 86400000);
  return Math.ceil((d + (date2.getDay() + 1 - 1)) / 7);
};
// 获取某个日期是周几
export const getWeekDay = (a, b, c) => {
  let str = '周';
  let str1 = `${a}-${b}-${c}`;
  return str + '日一二三四五六'.charAt(new Date(str1).getDay());
};

export const formatTime = (time, grain) => {
  const m = moment(time);
  switch (grain) {
    case 'hour':
      return m.format('YYYY/MM/DD h时');
    case 'day':
      return m.format('YYYY/MM/DD');
    case 'week':
      var from = m.format('MM/DD');
      var to = m.clone().add(6, 'day').format('MM/DD');
      return m.format(`YYYY/第w周(${from}-${to})`);
    case 'month':
      return m.format('YYYY/MM');
    default:
      return time;
  }
};
// yyyy-mm-dd
export function getDayTimer(data) {
  var date = new Date(data);
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  m = m < 10 ? '0' + m : m;
  var d = date.getDate();
  d = d < 10 ? '0' + d : d;
  var currentdate = y + '-' + m + '-' + d;
  return currentdate;
}
export function formatNum(str) {
  var newStr = '';
  var count = 0;
  // 当数字是整数
  str += '';
  if (str.indexOf('.') == -1) {
    for (var i = str.length - 1; i >= 0; i--) {
      if (count % 3 == 0 && count != 0) {
        newStr = str.charAt(i) + ',' + newStr;
      } else {
        newStr = str.charAt(i) + newStr;
      }
      count++;
    }
    str = newStr; //自动补小数点后两位
    return str;
  }
  // 当数字带有小数
  else {
    for (var i = str.indexOf('.') - 1; i >= 0; i--) {
      if (count % 3 == 0 && count != 0) {
        newStr = str.charAt(i) + ',' + newStr;
      } else {
        newStr = str.charAt(i) + newStr; //逐个字符相接起来
      }
      count++;
    }
    str = newStr + str.substr(str.indexOf('.'));
    return str;
  }
}
// 0-9 加小数点
export function validateIntNumberSpot(currNum) {
  // let rule = /^[+-]?((\d*(\.\d{1,})$)|(\d+$))/;
  let rule = /^[+-]?\d+\.?\d*$/;
  return rule.test(currNum);
}

// 大额数字单位转化为万, 增加千分位
export function formatThand(num, isFixedTwo) {
  num = Number(num);
  if (num == 0) {
    return (+num || 0).toString().replace(/^-?\d+/g, (m) => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','));
  }
  if (num > 0 && num < 10000) {
    if (isFixedTwo) {
      return (+num || 0)
        .toFixed(2)
        .toString()
        .replace(/^-?\d+/g, (m) => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','));
    } else {
      return (+num || 0)
        .toString()
        .replace(/^-?\d+/g, (m) => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','));
    }
  } else {
    let temp = (num / 10000)
      .toFixed(2)
      .toString()
      .replace(/^-?\d+/g, (m) => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','));
    return temp + '万';
  }
}

export function getRequest() {
  var url = window.location.search; //获取url中"?"符后的字串
  var theRequest = new Object();
  if (url.indexOf('?') != -1) {
    var str = url.substr(1);
    let strs = str.split('&');
    for (var i = 0; i < strs.length; i++) {
      theRequest[strs[i].split('=')[0]] = decodeURI(strs[i].split('=')[1]);
    }
  }
  return theRequest;
}

// 浏览器下载文件
export function browserDownload(content = null, fileName = null, fileType = null) {
  // 解析文件
  const blob = new Blob([content], { type: fileType });
  const url = URL.createObjectURL(blob);
  // 下载
  if ('download' in document.createElement('a')) {
    //非IE下载
    const a = document.createElement('a'); //创建一个a标签
    a.download = fileName; //指定文件名称
    a.style.display = 'none'; //页面隐藏
    a.href = url; // href用于下载地址
    document.body.appendChild(a); //插到页面上
    a.click(); //通过点击触发
    URL.revokeObjectURL(a.href); //释放URL 对象
    document.body.removeChild(a); //删掉a标签
  } else {
    //IE10 + 下载
    navigator.msSaveBlob(blob, fileName);
  }
}
// 返回数据解包
export const unpack = async function (thenable) {
  try {
    const result = await thenable;
    return result?.data ?? {};
  } catch (e) {
    throw e;
  }
};

export const calculateTimeDifference = function (startTime, endTime) {
  if (!startTime || !endTime) return '';
  let start = new Date(startTime); // 将开始时间转换为日期对象
  let end = new Date(endTime); // 将结束时间转换为日期对象

  let timeDiffInMs = Math.abs(end - start); // 获取时间差（单位：毫秒）

  let diffDays = Math.floor(timeDiffInMs / (1000 * 3600 * 24)); // 计算相差的天数
  let diffHours = Math.floor((timeDiffInMs % (1000 * 3600 * 24)) / (1000 * 3600)); // 计算相差的小时数
  let diffMinutes = Math.floor((timeDiffInMs % (1000 * 3600)) / (1000 * 60)); // 计算相差的分钟数

  return (
    (diffDays ? diffDays + '天 ' : '') +
    (diffHours ? diffHours + '小时 ' : '') +
    (diffMinutes ? diffMinutes + '分钟' : '')
  );
};
export function convertBlobToText(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      // 文件读取成功，event.target.result即为转换后的文本内容
      resolve(event.target.result);
    };
    reader.onerror = function (error) {
      // 文件读取失败
      reject(error);
    };
    // 以文本格式读取Blob
    reader.readAsText(blob);
  });
}
export const sleep = (s) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, s * 1000);
  });
};
//检查是否为空值
export const isEmptyValue = (value) => {
  // 检查 undefined 或 null
  if (value === undefined || value === null || value === '') return true;

  // 检查空数组
  if (Array.isArray(value) && value.length === 0) return true;

  // 检查空对象
  if (typeof value === 'object' && value !== null && Object.keys(value).length === 0) return true;

  // 其他情况返回 false
  return false;
};
//格式化空字符串
export const formatStrNull = (list) => {
  let finalList = [];
  if (list && list.length > 0) {
    list.map((item) => {
      let formatItem = {};
      Object.keys(item).map((key) => {
        if (isEmptyValue(item[key])) {
          formatItem[key] = '-';
        } else {
          formatItem[key] = item[key];
        }
      });
      finalList.push(formatItem);
    });
  }
  return finalList;
};
//省略文本
export const getOmitText = (content) => {
  return content.length > 10 ? `${content.slice(0, 10)}...` : content;
};
export const createRules = (formItems, isArray = true) => {
  const processItem = (item) => {
    const rules = [];
    let rule = {};
    if (item?.props?.isPhone) {
      rule = { validator: validatePhone, trigger: ['blur'] };
    } else {
      rule = {
        required: item?.props?.required ?? false,
        message: item?.props?.required ? `${item.placeholder || item.label}` : '',
        trigger: ['change'],
      };
    }
    rules.push(rule);
    return rules;
  };

  formItems = _.cloneDeep(formItems);

  if (isArray) {
    return formItems.reduce((acc, cur) => {
      acc[cur.mode] = processItem(cur);
      return acc;
    }, {});
  } else {
    return Object.keys(formItems).reduce((prev, key) => {
      prev[key] = processItem(formItems[key]);
      return prev;
    }, {});
  }
};
export const setFieldRequired = (item, rules, required) => {
  Object.keys(rules).forEach((key) => {
    if (key == item.mode) {
      const ruleArr = rules[key];
      ruleArr.forEach((rule) => {
        if (rule.hasOwnProperty('required')) {
          rule.required = required;
          if (rule.required) {
            rule.message = item.placeholder || item.label;
          }
        }
      });
    }
  });
};
export const phoneProps = {
  isNumber: true,
  isPhone: true,
  maxlength: 11,
};
export const moneyProps = {
  precision: 2,
  min: 0.0,
  controls: false,
  isMoney: true,
  max: 999999.0,
};
export const dateProps = {
  valueFormat: 'yyyy-MM-dd HH:mm:ss',
  type: 'datetime',
};
export const textAreaProps = {
  type: 'textarea',
  rows: 4,
  maxlength: 200,
  showWordLimit: true,
};
//生成一个全局唯一id
export const generateId = () => {
  return Math.random().toString(36).substr(2, 9);
};
