// 招商签约
const investmentSigning = () =>
  import(/* webpackChunkName: "investmentSigning" */ '@/investment/pages/investment/signing/index');
const investmentSigningNew = () =>
  import(/* webpackChunkName: "investmentSigning" */ '@/investment/pages/investment/signing/indexNew');
//新增/编辑/查看签约
const addEditDetailSigning = () =>
  import(
    /* webpackChunkName: "addEditDetailSigning" */ '@/investment/pages/investment/signing/addEditDetailSigning'
  );
// 招商政策
const investmentPolicies = () =>
  import(
    /* webpackChunkName: "investmentPolicies" */ '@/investment/pages/investment/policies/index'
  );
// 政策详情

const investmentPoliciesDetail = () =>
  import(
    /* webpackChunkName: "investmentPoliciesDetail" */ '@/investment/pages/investment/policies/detail'
  );
// 导出下载页
const download = () =>
  import(/* webpackChunkName: "download" */ '@/investment/pages/investment/download/index');
export default [
  {
    path: '/investment/signing',
    name: 'investmentSigning',
    component: investmentSigning,
    meta: {
      keepAlive: true,
      pageName: '招商签约',
    },
  },
  {
    path: '/investment/signingNew',
    name: 'investmentSigningNew',
    component: investmentSigningNew,
    meta: {
      keepAlive: true,
      pageName: '招商签约',
    },
  },
  {
    path: '/investment/addEditDetailSigning',
    name: 'addEditDetailSigning',
    component: addEditDetailSigning,
    meta: {
      pageName: '新增签约',
    },
  },
  {
    path: '/investment/policies',
    name: 'investmentPolicies',
    component: investmentPolicies,
    meta: {
      keepAlive: true,
      pageName: '招商政策',
    },
  },
  {
    path: '/investment/policies-detail',
    name: 'investmentPoliciesDetail',
    component: investmentPoliciesDetail,
    meta: {
      pageName: '新增政策',
    },
  },
  {
    path: '/investment/download',
    name: 'download',
    component: download,
    meta: {
      pageName: '导出列表',
    },
  },
];
