<template>
  <div class="step-fast-form-wrapper">
    <div class="step-wrapper" v-if="isStepsMode">
      <a-steps
        progress-dot
        v-model="currentStep"
        class="step-container"
        @change="onDotClick(index)"
      >
        <a-step
          v-for="(name, index) in stepNames"
          :key="index"
          :title="name"
          :disabled="isDisabled(index)"
        ></a-step>
      </a-steps>
    </div>
    <div class="step-content">
      <div class="step-content-item" v-for="(config, index) in formConfigs" :key="index">
        <div
          class="step-content-title"
          v-if="isGroupMode || (isExpandGroupMode && hasStepNamesStatus[index])"
        >
          <slot name="header" :config="config">
            <div
              @click="changeExpandStatus(index)"
              class="step-content-default-header"
              :style="stepNameBgColor[index]"
            >
              <span class="header-text">{{ stepNames[index] }}</span>
              <span v-if="isExpandGroupMode && isFoldable[index]">
                <a-icon
                  type="up-circle"
                  class="collapse-icon"
                  :class="expandStatus[index] ? 'collapse-icon-reverse' : ''"
                  :style="{ color: stepNameIconColor[index] }"
                />
                <!-- <a-icon type="caret-down" v-else /> -->
              </span>
            </div>
          </slot>
        </div>
        <div class="step-item-container" v-show="getExpandStepStatus(index)">
          <base-info-fast-form
            ref="baseForm"
            :config="config"
            @change="onChange($event)"
            v-show="isPureMode || isGroupMode || isExpandGroupMode || currentStep === index"
            @updateDevFormConfigAndvalues="updateDevFormConfigAndvalues"
          >
            <template v-for="(_, slotName) in $scopedSlots" v-slot:[slotName]="slotProps">
              <slot :name="slotName" v-bind="slotProps"></slot>
            </template>
            <slot v-for="(_, slotName) in $slots" :name="slotName" :slot="slotName"></slot>
          </base-info-fast-form>
        </div>
      </div>
    </div>
    <div class="step-footer" v-if="hasFooter">
      <slot name="footer"></slot>
      <a-space class="footer-content">
        <a-button @click="$router.back()">返回</a-button>
        <template v-if="!isGroupMode && !isExpandGroupMode">
          <a-button v-show="!hasNext" @click="onStepButtonClick(currentStep - 1)">上一步</a-button>
          <a-button type="primary" v-show="!hasLast" @click="onStepButtonClick(currentStep + 1)"
            >下一步</a-button
          >
        </template>
        <a-button
          type="primary"
          v-show="isGroupMode || isExpandGroupMode || hasLast"
          @click="onSubmit"
          >提交</a-button
        >
      </a-space>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import BaseInfoFastForm from './BaseInfoFastForm.vue';
import { describeColorType } from '@/pages/workflow/index';
import {
  computedDateUnitMap,
  computedUnitMap,
  isAsyncComp,
  removeUnitLabel,
} from '@/pages/workflow/components/formDesign/linkedFieldsUtils';
import {
  queryFormItemGangedByTaskRecordId,
  queryFormItemGangedByTemplateId,
  evaluatorRPN,
  evaluatorTime,
} from '@/api/workflow';
import getSnapshotParams from '@/pages/workflow/template/hooks/getSnapshotParams.js';
import dayjs from 'dayjs';
const relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);
const Parser = require('expr-eval').Parser;
const parser = new Parser();
const dateTypes = ['DateTime'];
import useInitDataDispose from '@/pages/workflow/hooks/useInitDataDispose';
const compareValues = (a, b, type, fieldType) => {
  if (dateTypes.includes(fieldType)) {
    a = dayjs(a);
    b = dayjs(b);
    const comparisonMap = {
      '==': a.isSame(b),
      '!=': !a.isSame(b),
      '<': a.isBefore(b),
      '>': a.isAfter(b),
      '<=': a.isSame(b) || a.isBefore(b),
      '>=': a.isSame(b) || a.isAfter(b),
    };
    return comparisonMap[type] ?? false;
  } else {
    if (['ComputedExpression', 'ComputedDate'].includes(fieldType)) {
      a = removeUnitLabel(a);
    }
    if (!isNaN(a)) {
      a = Number(a);
    }
    if (!isNaN(b)) {
      b = Number(b);
    }
    // console.log('🚀 ~ compareValues ~ a, b:', a, b);
    const comparisonMap = {
      '==': a == b,
      '!=': a != b,
      '<': a < b,
      '>': a > b,
      '<=': a <= b,
      '>=': a >= b,
    };
    // console.log('🚀 ~ compareValues ~ comparisonMap[type]:', comparisonMap[type]);
    return comparisonMap[type] ?? false;
  }
};
export default {
  components: { BaseInfoFastForm },
  data() {
    return {
      currentStep: 0,
      expandStatus: [],
      componentlinkageConfig: [],
      itemLengths: 0,
    };
  },
  props: {
    templateId: {
      type: String,
      default: '',
    },
    currentPage: {
      type: String,
      default: '',
    },
    // 是否允许点击步骤
    disabledClick: {
      type: Boolean,
      default: false,
    },
    // 是否为审批节点
    isApproval: {
      type: Boolean,
      default: false,
    },
    // 可查看数据配置
    viewConfigRef: {
      type: Object,
      default: () => {},
    },
    // 是否允许字段联动
    fieldDynamic: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
    },
    allFormData: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
  watch: {
    allFormData: {
      handler: 'allFormDataWatcher',
      immediate: true,
      deep: true,
    },
    config: {
      handler: 'configWatcher',
      immediate: true,
      deep: true,
    },
    async itemLengths(val) {
      // 初始化调用一次
      if (val && !this.componentlinkageConfig.length) {
        await this.getComponentlinkageConfig();
        this.updateFormConfigAndvalues();
      }
    },
  },
  async mounted() {
    if (this.itemLengths && !this.componentlinkageConfig.length) {
      await this.getComponentlinkageConfig();
      this.updateFormConfigAndvalues();
    }
  },
  methods: {
    // 单个值更新配置
    async updateDevFormConfigAndvalues(formItem) {
      if (formItem && (formItem.itemCode === 'KFZJ' || formItem.itemCode === 'KFJL')) {
        // 更新开发总监/组别配置
        const flattenedItems = this.config.steps.flatMap((step) => step.items);
        for (let item of flattenedItems) {
          item = item ?? {};
          if (item.dataIndex === formItem.dataIndex) {
            item.options = formItem.userPicker;
            item.userPicker = formItem.userPicker;
            break; // 匹配到 item 后跳出循环
          }
        }
      }

      if (formItem && (formItem.itemCode === 'ZSZJ' || formItem.itemCode === 'ZSJL')) {
        // 更新招商总监/组别配置
        const flattenedItems = this.config.steps.flatMap((step) => step.items);
        for (let item of flattenedItems) {
          item = item ?? {};
          if (item.dataIndex === formItem.dataIndex) {
            item.options = formItem.userPicker;
            item.userPicker = formItem.userPicker;
            break; // 匹配到 item 后跳出循环
          }
        }
      }
      this.$emit('update:config', _.cloneDeep(this.config));
      this.$emit('updateSubmitFormConfig', [formItem]);
    },
    async updateFormConfigAndvalues() {
      if (!this.fieldDynamic) return;
      console.log('更新了');
      // 重置校验
      this.$store.commit('setAsyncConditionFormItems', []);
      this.$store.commit('setFlattenedItems', []);
      const allFormData = this.allFormData || {};
      const allFormDataKeys = Object.keys(allFormData);
      const viewFlattenedItemKeys = (this.viewConfigRef?.steps || [])
        .flatMap((step = {}) => step.items || [])
        .map((item = {}) => item.dataIndex);

      let canDynamicFields = [];
      if (this.currentPage === 'editForm') {
        canDynamicFields = allFormDataKeys;
      } else {
        canDynamicFields = allFormDataKeys.filter((key) => !viewFlattenedItemKeys.includes(key));
      }
      await this.updateValues(
        this.config,
        allFormData,
        this.componentlinkageConfig,
        canDynamicFields,
      );
      this.$nextTick(() => {
        this.updateFormConfigs(
          this.config,
          allFormData,
          this.componentlinkageConfig,
          canDynamicFields,
        );
      });
    },
    async getComponentlinkageConfig() {
      if (!this.fieldDynamic) return;
      const { bizId, messageId } = this.$route.query;
      const isEditForm = this.currentPage === 'editForm';
      const id = isEditForm ? this.templateId : bizId;
      if (!id && !messageId) return;
      let snapshotParams = getSnapshotParams();
      const api = isEditForm ? queryFormItemGangedByTemplateId : queryFormItemGangedByTaskRecordId;
      const param = isEditForm
        ? { templateId: id, messageId }
        : { taskRecordId: id, messageId, ...snapshotParams };
      const { data } = await api(param);
      this.componentlinkageConfig = data;
    },
    isEmptyValue(value) {
      // 检查 undefined 或 null
      if (value === undefined || value === null || value === '') return true;

      // 检查空数组
      if (Array.isArray(value) && value.length === 0) return true;

      // 检查空对象
      if (typeof value === 'object' && value !== null && Object.keys(value).length === 0)
        return true;

      // 其他情况返回 false
      return false;
    },
    updateFormConfigs(formConfig, allFormData, linkageConfig, canDynamicFields) {
      try {
        formConfig = _.cloneDeep(formConfig);
        if ([formConfig, allFormData, linkageConfig].some(this.isEmptyValue)) return;
        const gangedFieldIdMap = {};
        const defaultRules = [{ required: false, message: '' }];
        const flattenedItems = formConfig.steps.flatMap((step) => step.items);
        linkageConfig.forEach((config) => {
          const {
            fieldId,
            fieldType,
            gangedFieldId,
            conditionType,
            conditionValue,
            gangedStatus,
            gangedValue,
          } = config;
          if (!gangedFieldIdMap[gangedFieldId]) {
            gangedFieldIdMap[gangedFieldId] = [];
          }
          const fieldValue = allFormData[fieldId];
          let conditionItem = {
            rules: _.cloneDeep(defaultRules),
          };
          for (let item of flattenedItems) {
            item = item ?? {};
            if (this.isEmptyValue(item.rules)) {
              item.rules = _.cloneDeep(defaultRules);
            }
            if (item.dataIndex === gangedFieldId) {
              // 保存原始状态
              if (!item._originalState) {
                item._originalState = {
                  rules: _.cloneDeep(item.rules),
                };
              }
              conditionItem = item;
              // 被联动赋值的字段需要禁用
              if (!this.isEmptyValue(gangedValue)) {
                conditionItem.disabled = true;
              }
              break; // 匹配到 item 后跳出循环
            }
          }
          const changeConfig = (item, visible, newRequiredValue) => {
            if (canDynamicFields.includes(item.dataIndex)) {
              item.visible = visible;
              item.rules.forEach((rule = {}) => {
                rule.required = newRequiredValue;
                rule.message = newRequiredValue ? '请填写' + item.label : '';
              });
            }
          };
          if (!this.isEmptyValue(fieldValue)) {
            let conditionMet = compareValues(fieldValue, conditionValue, conditionType, fieldType);
            if (conditionMet) {
              gangedFieldIdMap[gangedFieldId].push(true);
              switch (gangedStatus) {
                case 'hidden':
                  //对修改表单特殊处理
                  if (this.currentPage === 'editForm') {
                    changeConfig(conditionItem, true, false);
                  } else {
                    changeConfig(conditionItem, false, false);
                  }
                  break;
                case 'required':
                  changeConfig(conditionItem, true, true);
                  break;
                case 'unRequired':
                  changeConfig(conditionItem, true, false);
                  break;
              }
            } else {
              gangedFieldIdMap[gangedFieldId].push(false);
            }
          }
        });
        //没有一个匹配的重置为初始规则
        Object.keys(gangedFieldIdMap).forEach((key) => {
          if (gangedFieldIdMap[key].every((i) => i === false)) {
            flattenedItems.forEach((item = {}) => {
              if (item.dataIndex === key && canDynamicFields.includes(key)) {
                item.visible = true;
                item.rules = _.cloneDeep(item._originalState.rules);
              }
            });
          }
        });
        this.$emit('update:config', _.cloneDeep(formConfig));
        this.$nextTick(() => {
          console.log('formConfig==', formConfig);
          this.$store.commit('setFlattenedItems', flattenedItems);
        });
      } catch (error) {
        console.error('Error in updateFormConfigs:', error);
      }
    },
    configWatcher(val) {
      this.itemLengths = (val?.steps || [])[0]?.items?.length;
      // 展开收起状态初始化
      if (this.isExpandGroupMode) {
        const arr = val?.steps?.map((step) => {
          return step.defaultFold ?? false;
        });
        this.expandStatus = [...arr];
      }
    },
    // 展示step表格
    getExpandStepStatus(index) {
      let status = true;
      if (this.isExpandGroupMode && this.hasStepNamesStatus[index] && this.isFoldable[index]) {
        status = !this.expandStatus[index];
      }
      return status;
    },
    changeExpandStatus(index) {
      if (this.isExpandGroupMode && this.hasStepNamesStatus[index] && this.isFoldable[index]) {
        const target = this.expandStatus[index];
        this.$set(this.expandStatus, index, !target);
      }
    },
    onChange(val) {
      if (_.isNull(this.allFormData)) {
        return;
      }
      // 深度遍历，两次变更数据是否相等
      const isEqual = Object.keys(val).every((key) => {
        return _.isEqual(this.allFormData[key], val[key]);
      });
      // 如果不同，更新allformData数据
      if (!isEqual) {
        // 遍历val对象，把值复制到 allformData中去
        Object.keys(val).forEach((key) => {
          this.allFormData[key] = val[key];
        });
        this.$emit('update:allFormData', this.allFormData);

        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.updateFormConfigAndvalues();
        }, 800);
      }
    },
    async updateValues(formConfig, allFormData, componentlinkageConfig, canDynamicFields) {
      try {
        const linkConfigs = componentlinkageConfig.filter((config) => {
          return !this.isEmptyValue(config.gangedValue);
        });
        const isAsyncConditionFormItems = (formConfig.steps || []).flatMap((step) =>
          (step.items || []).filter((field) => isAsyncComp.includes(field.oldType)),
        );
        if (isAsyncConditionFormItems.length) {
          let promises = [];
          for (let item of isAsyncConditionFormItems) {
            if (item.oldType === 'ComputedExpression') {
              promises.push(this.computedCount(item, allFormData));
            } else if (item.oldType === 'ComputedDate') {
              promises.push(this.computedDate(item, allFormData));
            }
          }
          const res = await Promise.all(promises);
          // 给所有异步字段赋值
          isAsyncConditionFormItems.forEach((formItem) => {
            res.forEach((resItem) => {
              if (!this.isEmptyValue(resItem)) {
                const [key, value] = Object.entries(resItem)[0];
                if (formItem.dataIndex === key && canDynamicFields.includes(key)) {
                  allFormData[formItem.dataIndex] = value;
                  formItem.value = value;
                }
              }
            });
          });
          this.$store.commit(
            'setAsyncConditionFormItems',
            isAsyncConditionFormItems.filter((i) => canDynamicFields.includes(i.dataIndex)),
          );
        }
        const formDataKeys = Object.keys(allFormData);
        // 给配置中联动的字段赋值
        const gangedFieldIdMap = {};
        for (const item of linkConfigs) {
          const { fieldId, conditionValue, conditionType, fieldType, gangedFieldId, gangedValue } =
            item;
          if (!gangedFieldIdMap[gangedFieldId]) {
            gangedFieldIdMap[gangedFieldId] = [];
          }
          for (const key of formDataKeys) {
            if (fieldId === key && !this.isEmptyValue(allFormData[key])) {
              if (compareValues(allFormData[key], conditionValue, conditionType, fieldType)) {
                if (canDynamicFields.includes(gangedFieldId)) {
                  allFormData[gangedFieldId] = gangedValue;
                }
                gangedFieldIdMap[gangedFieldId].push(true);
              } else {
                gangedFieldIdMap[gangedFieldId].push(false);
              }
            }
          }
        }
        Object.keys(gangedFieldIdMap).forEach((key) => {
          if (gangedFieldIdMap[key].every((item) => item === false)) {
            if (canDynamicFields.includes(key)) allFormData[key] = '';
          }
        });
        return Promise.resolve();
      } catch (error) {
        console.error('Error in updateValues:', error);
        return Promise.reject(error);
      }
    },
    computedDate(ComputedDateItem = {}, allFormData) {
      return new Promise(async (resolve) => {
        // 提取表达式列表 - 填充值
        let { expressionList = [] } = ComputedDateItem;
        if (!expressionList.length) return resolve({});
        expressionList.forEach((item) => {
          if (item.type === 'form') {
            const value = allFormData[item.bizId];
            item.value = this.isEmptyValue(value) ? '' : value;
          }
        });
        // 任意一个日期不存在返回空对象
        if (expressionList.some((i) => this.isEmptyValue(i.value))) return resolve({});
        try {
          // const res = await evaluatorTime({
          //   unit: ComputedDateItem.numberType,
          //   data: expressionList,
          // });
          const endDate = dayjs(expressionList[0].value);
          const startDate = dayjs(expressionList[2].value);
          const unit = computedDateUnitMap.find((i) => i.value == ComputedDateItem.numberType).unit;
          // 计算差值
          const data = endDate.diff(startDate, unit, true);
          const obj = {
            [ComputedDateItem.dataIndex]: this.dateFormatUnit(ComputedDateItem.numberType, data),
          };
          resolve(obj);
        } catch (e) {
          resolve({});
        }
      });
    },
    dateFormatUnit(numberType, value) {
      const formatValue = value.toFixed(1);
      const unitLabel = computedDateUnitMap.find((i) => i.value == numberType).label;
      return `${formatValue}${unitLabel}`;
    },
    computedCount(ComputedExpressionItem = {}, allFormData) {
      return new Promise(async (resolve) => {
        // 提取表达式列表 - 填充值
        let { expressionList = [] } = ComputedExpressionItem;
        if (!expressionList.length) return resolve({});
        expressionList.forEach((item) => {
          if (item.type === 'form') {
            const value = allFormData[item.bizId];
            item.value = this.isEmptyValue(value) ? '' : value;
          }
        });
        // 任意一个值不存在返回空对象
        if (expressionList.some((i) => this.isEmptyValue(i.value))) return resolve({});
        const expression = expressionList.map((item) => item.value).join('');
        // 1-保留小数点2位，2-百分比，3-保留整数
        try {
          // const res = await evaluatorRPN({
          //   unit: ComputedExpressionItem.numberType,
          //   data: expressionList,
          // });
          const data = parser.parse(expression).evaluate();
          const obj = {
            [ComputedExpressionItem.dataIndex]: this.formatUnit(
              ComputedExpressionItem.numberType,
              data,
            ),
          };
          resolve(obj);
        } catch (e) {
          resolve({});
        }
      });
    },
    formatUnit(numberType, value) {
      const getFormatValue = (numberType, value) => {
        if (numberType == 1) {
          return value.toFixed(2);
        } else if (numberType == 2) {
          return (value * 100).toFixed(2);
        } else if (numberType == 3) {
          return Math.round(value) + '';
        }
      };
      const formatValue = getFormatValue(numberType, value);
      const unitLabel = computedUnitMap.find((i) => i.value == numberType).label;
      return `${formatValue}${unitLabel}`;
    },
    allFormDataWatcher(val) {
      this.$nextTick(() => {
        this.formInitValue(val);
      });
    },
    isDisabled(index) {
      return this.currentStep < index;
    },
    // 切换当前step
    setCurrentStep(index) {
      this.currentStep = index;
    },
    // 校验当前的表单组件
    async validateCurrentForm() {
      let result = {};
      // 如果是分步模式，校验当前表单
      if (this.isStepsMode) {
        result = await this.$refs.baseForm[this.currentStep].validateFields();
      } else {
        // 如果是分组模式，校验所有表单
        result = Promise.all(
          this.$refs.baseForm.map((form) => {
            return form.validateFields();
          }),
        );
      }
      return result;
    },
    // 获得所有的表单值并组合成对象返回
    getAllFormData() {
      const formData =
        this.$refs?.baseForm?.map((form, index) => {
          // 使用一个数组把内容传出去
          const value = form.getFieldsValue();
          // 使用一个对象把内容传出去
          return this.config?.steps[index]?.items.reduce((total, item, index) => {
            const dataIndex = item.dataIndex; // 获得数据索引
            const hasCustomRender = item?.scopedSlots?.customRender ?? null;
            // 如果当前数据为非受控组件直接取值
            if (!hasCustomRender) {
              total[dataIndex] = value[dataIndex];
              // 如果是受控组件，优先从allformdata中取值，拿不到再走默认取值
            } else if (hasCustomRender && !_.isNull(this.allFormData)) {
              total[dataIndex] = this.allFormData[dataIndex];
            } else {
              total[dataIndex] = value[dataIndex];
            }
            return total;
          }, {});
        }) ?? [];
      const ObjValue = formData.reduce((total, current) => {
        total = { ...total, ...current };
        return total;
      }, {});
      return ObjValue;
    },
    // 提交表单数据事件
    onSubmit: _.debounce(
      async function () {
        this.$emit('submit', await this.validateFields());
      },
      200,
      { leading: true, trailing: false },
    ),
    // 获得提交的表单值
    async validateFields() {
      let value = '';
      try {
        // 校验当前表单
        await this.validateCurrentForm();
        value = this.getAllFormData();
      } catch (error) {
        const errors = error?.errors ?? [];
        const emptyKey = Object.keys(errors)[0];
        if (emptyKey && emptyKey != 'notifyUsers') {
          const element = document.getElementById(emptyKey);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            // 添加高亮闪动动画类
            element.classList.add('highlight');
            // 在动画结束后移除高亮闪动动画类
            setTimeout(() => {
              element.classList.remove('highlight');
            }, 3500);
          }
        }
        value = error;
      }
      return value;
    },
    // 初始化表单值
    formInitValue(val) {
      if (!_.isEmpty(val)) {
        // 遍历拿到每个表单ref对象
        this.$refs?.baseForm?.forEach((formRef, index) => {
          // 从每一步的表单配置对象中，找到输入框的索引，将key和value赋值到setValue中
          const setValue = this.config?.steps[index]?.items?.reduce((total, current) => {
            const dataIndex = current.dataIndex;
            const data = val[dataIndex] ?? current.initialValue;
            // 处理多级查询的状况
            // if(dataIndex.indexOf('.')!==-1){
            //   const keys = dataIndex.split('.');

            // }
            total[dataIndex] = data;
            return total;
          }, {});
          /*
          setValue = {
            name: '',
            number: 0123,
          }
          */
          //  将值设置到对应的表单中
          formRef.setFieldsValue(setValue);
        });
      } else {
        this.resetForm();
        // 获得表单的值，把值同步给表单
      }
    },
    // 步骤变化
    onStepChange(index) {
      this.currentStep = index;
    },
    // 步骤点的点击事件
    async onDotClick(index) {
      if (this.disabledClick) {
        return;
      }
      try {
        await this.validateCurrentForm();
      } catch (error) {
        this.onStepChange(this.currentStep);
        console.log(error);
      }
    },
    // 上一步下一步点击事件
    async onStepButtonClick(index) {
      try {
        // 下一步
        if (this.currentStep < index) {
          await this.validateCurrentForm();
          this.onStepChange(index);
          // 上一步
        } else {
          this.onStepChange(index);
        }
      } catch (error) {
        console.log(error);
      }
    },
    last() {
      const currentStep = this.currentStep - 1;
      this.onStepChange(currentStep >= 0 ? currentStep : 0);
    },
    // 重置当前表单数据
    resetForm() {
      this.$refs?.baseForm?.forEach((formRef, index) => {
        //  将值设置到对应的表单中
        formRef.resetFields();
      });
    },
  },
  computed: {
    mode() {
      return this.config?.mode ?? 'steps';
    },
    isStepsMode() {
      return this.mode === 'steps';
    },
    isGroupMode() {
      return this.mode === 'group';
    },
    isExpandGroupMode() {
      return this.mode === 'expandGroup';
    },
    isPureMode() {
      return this.mode === 'pure';
    },
    hasFooter() {
      return _.isUndefined(this.$slots.footer) && (this.config?.showFooter ?? true);
    },
    stepNames() {
      return this.config?.steps?.map((step) => {
        return step.name ?? '';
      });
    },
    // this.mode === 'expandGroup'模式下
    // type为'none'则不显示分隔栏，为'Description'则显示分隔栏
    hasStepNamesStatus() {
      return this.config?.steps?.map((step) => {
        return step.type === 'Description' ?? false;
      });
    },
    // 分组栏的背景色边框色
    stepNameBgColor() {
      return this.config?.steps?.map((step) => {
        const color = step.color ?? 'default';
        const describeColorObj = describeColorType[color] ?? describeColorType['default'];
        const { bgColor = '', borderColor = '' } = describeColorObj;
        if (bgColor && borderColor) {
          return {
            backgroundColor: bgColor,
            // border: `1px solid ${borderColor}`,
          };
        }
        return {};
      });
    },
    stepNameIconColor() {
      let arr = this.config?.steps?.map((step) => {
        const color = step.color ?? 'default';
        const describeColorObj = describeColorType[color] ?? describeColorType['default'];
        const { bgColor = '', borderColor = '' } = describeColorObj;
        return borderColor;
      });
      return arr;
    },
    // 分组可折叠
    isFoldable() {
      return this.config?.steps?.map((step) => {
        return step.foldable ?? false;
      });
    },
    hasNext() {
      return _.isUndefined(this.stepNames[this.currentStep - 1]);
    },
    hasLast() {
      return _.isUndefined(this.stepNames[this.currentStep + 1]);
    },
    formConfigs() {
      return (
        this.config?.steps?.map((step) => {
          return { ...step, labelAlign: 'right' };
        }) ?? {}
      );
    },
  },
};
</script>

<style lang="scss">
.step-fast-form-wrapper {
  height: 100%;
  background-color: transparent;
  //padding: 0 15px 15px;
  .step-content-default-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // height: 45px;
    background-color: #e9ecf2;
    // color: black;
    // font-size: 14px;
    // padding-left: 12px;
    // padding-right: 12px;
    // line-height: 45px;
    // border-radius: 4px;
    margin: 12px 0;

    padding: 12px 16px;
    .header-text {
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #1a1a1a;
      line-height: 22px;
    }
    .collapse-icon {
      transition: transform 0.5s;
      font-size: 16px;
      &.collapse-icon-reverse {
        transform: rotate(180deg);
      }
    }
  }
  .step-content-item:not(:nth-of-type(1)) .step-content-default-header {
    margin: 12px 0;
  }
  .step-wrapper {
    height: 110px;
    background: rgba(255, 255, 255, 1);
    border-radius: 4px;
    margin: 12px auto 24px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    padding-top: 30px;
    .step-container {
      margin: 0 20%;
    }
  }
  .step-content {
    background: rgba(255, 255, 255, 1);
    border-radius: 4px 4px 0 0;
  }
  .step-footer {
    background: rgba(255, 255, 255, 1);
    padding: 28px;
    border-radius: 0 0 4px 4px;
    .footer-content {
      margin-left: 50%;
      transform: translate(-50%, 0);
    }
  }
}
</style>
