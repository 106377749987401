//智能巡店--门店信息
const patrolBaseStore = () => import('@/pages/patrolTask/storeInfo/list.vue');
const patrolBaseStoreDetail = () => import('@/pages/patrolTask/storeInfo/detail.vue');

//系统设置-门店标签
const patrolBaseStoreTag = () => import('@/pages/patrolTask/storeInfo/Tag/index.vue');
export default [
  {
    path: '/patrolTask/baseStore',
    name: 'patrolBaseStore',
    component: patrolBaseStore,
    meta: {
      keepAlive: true,
      pageName: '门店列表',
    },
  },
  {
    path: '/patrolTask/patrolBaseStoreDetail',
    name: 'patrolBaseStoreDetail',
    component: patrolBaseStoreDetail,
    meta: {
      keepAlive: false,
      pageName: '查看',
    },
  },
  {
    path: '/patrolTask/baseStoreTag',
    name: 'patrolBaseStoreTag',
    component: patrolBaseStoreTag,
    meta: {
      keepAlive: true,
      pageName: '门店标签',
    },
  },
];
