import { render, staticRenderFns } from "./FastText.vue?vue&type=template&id=9c09c772&scoped=true"
import script from "./FastText.vue?vue&type=script&setup=true&lang=js"
export * from "./FastText.vue?vue&type=script&setup=true&lang=js"
import style0 from "./FastText.vue?vue&type=style&index=0&id=9c09c772&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c09c772",
  null
  
)

export default component.exports