import Vue from 'vue';
import App from './App.vue';
import './plugins/antd.js';

import './plugins/element.js';

import router from './router';
import store from './store';
import MzDataWrapper from '@/components/MzDataWrapper';
import Ellipsis from '@/components/Ellipsis';
import WDialog from '@/components/WDialog';
import Breadcrumb from '@/components/MzAntD/Breadcrumb';
import MzWaterMark from '@/components-antd/MzWaterMark';
import MzIcon from '@/components-antd/MzIcon/index.js';
import MzPrdIcon from '@/components-antd/MzIcon/production.js';
import * as filters from './filters';
import vSelect from 'vue-select';
import VueWorker from 'vue-worker';
import infiniteScroll from 'vue-infinite-scroll';
import 'vue-select/dist/vue-select.css';
import components from './components';
import '@/util/global';
import less from 'less';
import './config';
import Tab from '@/components/customerOperation/Tab';
import './assets/css/theme.css';
// MzTeleport
import Teleport from '@/components/MzTeleport';
import moment from 'moment';
import 'moment/locale/zh-cn';
import '@/common/style/workflow.scss';
import modalPlugin from '@/components/modalPlugin';
import { trim } from '@/util/directives.js';
Vue.directive('trim', trim);
moment.locale('zh-cn');
Vue.prototype.$moment = moment;

import '@/assets/iconfont/symbol/menu';
import 'jsoneditor/dist/jsoneditor.min.css';

import './assets/css/index.scss';
import ExportDialog from '@/investment/components/ExportDialog';
import ExportDialogPlugin from '@/investment/components/ExportDialogPlugin.js';

import BaseForm from '@/investment/components/options/base-form.vue';


import mixins from './minxins/index.js'
Vue.mixin(mixins)

Vue.component('ExportDialog', ExportDialog);
Vue.use(ExportDialogPlugin);
Vue.component('BaseForm', BaseForm);

// 是否为测试环境
window.isDevelopment = process.env.NODE_ENV == 'development';
Vue.prototype.isDevelopment = process.env.NODE_ENV == 'development';

//按需引入antd
Vue.use(less);
Vue.use(modalPlugin);
Vue.directive('drag', {
  inserted: function (el) {
    el.onmousedown = function (ev) {
      var disX = ev.clientX - el.offsetLeft;
      var disY = ev.clientY - el.offsetTop;
      document.onmousemove = function (ev) {
        var l = ev.clientX - disX;
        var t = ev.clientY - disY;
        el.style.left = l + 'px';
        el.style.top = t + 'px';
      };
      document.onmouseup = function () {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    };
  },
});

Vue.directive('dragInner', {
  inserted: function (el, binding, vnode) {
    console.log('el, binding, vnode===>', el, binding, vnode);
    if (binding.value) return;
    const parentNode = el.parentNode;
    el.onmousedown = function (ev) {
      var disX = ev.clientX - el.offsetLeft;
      var disY = ev.clientY - el.offsetTop;

      let pW = parentNode.clientWidth;
      let pH = parentNode.clientHeight;
      let eW = el.offsetWidth;
      let eH = el.offsetHeight;

      document.onmousemove = function (ev) {
        var l = ev.clientX - disX;
        var t = ev.clientY - disY;

        if (l < 0) {
          l = 0;
        }
        if (l > pW - eW) {
          l = pW - eW;
        }
        if (t < 0) {
          t = 0;
        }
        if (t > pH - eH) {
          t = pH - eH;
        }
        el.style.left = l + 'px';
        el.style.top = t + 'px';
      };
      document.onmouseup = function () {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    };
  },
});

Vue.directive('dragY', {
  inserted: function (el) {
    let clientHeight = document.body.clientHeight;
    el.onmousedown = function (ev) {
      var disY = ev.clientY - el.offsetTop;
      document.onmousemove = function (ev) {
        var t = ev.clientY - disY;
        console.log('===>', t <= 48);
        if (t > 48 && t <= clientHeight - 58) {
          el.style.top = t + 'px';
        }
        setTimeout(() => {
          window.mouseup_click_debug = true;
        }, 200);
      };
      document.onmouseup = function () {
        document.onmousemove = null;
        document.onmouseup = null;
        setTimeout(function () {
          window.mouseup_click_debug = false;
        }, 200);
      };
    };
  },
});
// install Components
Vue.use(components);

Vue.component('Teleport', Teleport);
Vue.component('v-select', vSelect);
// global filters
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});
import './assets/czm_icon/iconfont.css';
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
Vue.component(CollapseTransition.name, CollapseTransition);

Vue.component('mz-data-wrapper', MzDataWrapper);
Vue.component('ellipsis', Ellipsis);
Vue.component('w-dialog', WDialog);
Vue.component('MzIcon', MzIcon);
Vue.component('Breadcrumb', Breadcrumb);
Vue.component('MzPrdIcon', MzPrdIcon);
Vue.component('MzTab', Tab);
Vue.component('MzWaterMark', MzWaterMark);

Vue.use(VueWorker);
Vue.use(infiniteScroll);
Vue.use({
  install(Vue) {
    Vue.prototype.$bus = new Vue();
  },
});
Vue.config.productionTip = false;

Vue.prototype.$loading_type = false;
/**
 * 获取屏幕宽高
 */
Vue.prototype.$getViewportSize = function () {
  return {
    width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth, //兼容性获取屏幕宽度
    height:
      window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight, //兼容性获取屏幕高度
  };
};
/**
 * 判断一个url是否是视频
 * @param {String} videoUrl  要判断的url
 * @returns
 */
const pattern = /^https?.+\.(mp4|MP4|m4v|M4V|avi|AVI|m3u8|M3U8|webm|WEBM|mov|MOV|3gp|3GP)$/i;
Vue.prototype.$isVideoTest = (videoUrl = '') => {
  let flag = false;
  if (!videoUrl || videoUrl.trim() == '') return flag;
  flag = pattern.test(videoUrl);
  return flag;
};

window.vm = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
